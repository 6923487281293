.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 450px;
  padding: 20px;

  .logo {
    width: 300px;
    margin-bottom: 20px;
  }

  a {
    color: white;
    text-decoration: none;
  }

  .copyright {
    margin-top: 30px;
    color: white;
  }
}
