@import "../../variables.module.scss";

.container {
  display: flex;
  font-size: 18px;
  flex-direction: column;
  border-radius: 18px;
  padding: 20px 60px;
  background-color: #ffffffe6;
  box-shadow: #63636333 0 2px 8px;

  p {
    color: inherit;
  }

  a {
    color: #1990c6;
    text-decoration: none;
    border-bottom: 1px solid #8b8b8b;
  }

  input,
  textarea {
    width: 100%;
    margin-bottom: 20px;
  }
}
.title {
  margin: 0;
  font-size: 29.5px;
}

@media (max-width: $mobileBreakPoint) {
  .container {
    padding: 20px;
  }
}
