@import "../../variables.module.scss";

.sectionContainer {
  border-bottom: 1px solid white;
}
.section {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-size: cover;
  background-position: center center;
  padding: 60px;
  overflow: hidden;

  &.fullScreen {
    height: 100vh;
  }

  p {
    color: white;
  }
}
.socialList {
  position: absolute;
  top: 60px;
}
.arrowDown {
  position: absolute;
  cursor: pointer;
  bottom: 20px;
}
.logo {
  width: 400px;
}
.videoSection {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;
  height: auto;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}
.sectionImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.aboutMeTitle {
  font-size: 35px;
  letter-spacing: 0.2em;
  display: inline;
  border-bottom: 2px solid #1990c6;
}
.aboutMeText {
  margin-top: 20px;
  line-height: 1.6;
  font-size: 18px;
}
.testimonialsTitle {
  letter-spacing: 0.2em;
  margin-bottom: 40px;
}
.mit4mit_wed_reviews_Container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  img {
    width: 300px;
  }
}
.gallery {
  display: flex;
  flex-wrap: wrap;

  .galleryImageWrapper {
    position: relative;
    height: 200px;
    width: 12.5%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (max-width: 1200px) {
  .gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .galleryImageWrapper {
      height: auto;
      width: 100%;
      padding-top: 100%;
    }
  }
}

@media (max-width: $mobileBreakPoint) {
  .section {
    padding: 20px;
  }

  .logo {
    opacity: 0.75;
    width: 320px;
  }

  .mit4mit_wed_reviews_Container {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 20px;
  }

  .gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .galleryImageWrapper {
      height: auto;
    }
  }
}
