body,
html,
p,
span,
h1,
h2,
h3,
input,
textarea {
  color: #333;
  font-family:
    Abel,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Ubuntu,
    Helvetica Neue,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol;
}
img {
  display: block;
}
div {
  box-sizing: border-box;
}
input,
textarea {
  font-size: 14px;
  box-sizing: border-box;
  display: block;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  padding: 10px;
  border: 2px solid #999;
  max-width: 100%;
  line-height: 1.42;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Ubuntu,
    Helvetica Neue,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol;
  background-color: transparent;
}
.button {
  position: relative;
  display: inline-flex;
  font-size: 14px;
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 10px 20px;
  background-color: black;
  color: white;
}
.button.isLoading {
  pointer-events: none;
  color: transparent;
}
.button.isLoading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
textarea {
  min-height: 250px;
}
@media (max-width: var(--mobileBreakpoint)) {
  .section {
    padding: 20px;
  }

  .logo {
    opacity: 0.75;
    width: 320px;
  }

  .mit4mit_wed_reviews_Container {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 20px;
  }

  .gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .galleryImageWrapper {
      height: auto;
    }
  }
}
